import { createStore } from 'vuex';
import axios from 'axios';
import router from './router';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

const store = createStore({
  state: {
    user: null,
    timerRunning: false,
    remainingSeconds: 120,
    timerInterval: null,
  },
  mutations: {
    setUser(state, user) {
      console.log('Setting user:', user);
      state.user = { email: user._json.email };
    },
    clearUser(state) {
      state.user = null;
      state.timerRunning = false;
      state.remainingSeconds = 120;
      clearInterval(state.timerInterval);
    },
    startTimer(state, timeDiffInSeconds) {
      if (state.timerInterval) return;

      state.remainingSeconds = 120 - Math.trunc(timeDiffInSeconds);
      state.timerRunning = true;
      state.timerInterval = setInterval(() => {
        state.remainingSeconds -= 1;
        if (state.remainingSeconds <= 0) {
          clearInterval(state.timerInterval);
          state.timerInterval = null;
          state.timerRunning = false;
          state.remainingSeconds = 120;
          console.log('Timer expired');
        }
      }, 1000);
    },
  },
  actions: {
    async fetchUser({ commit }) {
      try {
        const response = await axios.get('/api/user');
        console.log('User data:', response.data);
        commit('setUser', response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log('User is not authenticated');
          return;
        }
        console.log('Error fetching user');
        console.error(error);
        commit('clearUser');
      }
    },
    logout({ commit }) {
      commit('clearUser');
      router.push('/');
    },
    async fetchLastObservationTime({ commit }) {
      try {
        const response = await axios.get('/api/query-user-last-observation-time');
        console.log('Last observation time:', response.data);
        const localTime = new Date();
        const localTimeUtc = new Date(localTime.getTime() + localTime.getTimezoneOffset() * 60000);
        console.log('Current time in UTC:', localTimeUtc);
        const timeDiffInSeconds = (localTime - new Date(response.data)) / 1000;
        console.log('Time difference in seconds:', timeDiffInSeconds);
        if (timeDiffInSeconds < 120) {
          console.log('User has observed a parking spot in the last 2 minutes');
          commit('startTimer', timeDiffInSeconds);
        }
        // commit('setLastObservationTime', response.data);
      } catch (error) {
        console.log('Error fetching last observation time');
        console.error(error);
      }
    },
  },
  getters: {
    user: state => state.user,
    isAuthenticated: state => !!state.user,
    remainingSeconds: (state) => state.remainingSeconds,
    timerRunning: state => state.timerRunning,
  }
});

export default store;
