/**
 * Calculates the number of hours ago from the given date to the current time.
 *
 * @param {string|Date} date - The date to calculate the hours ago from. Can be a date string or a Date object.
 * @returns {number} The number of hours ago from the given date to now.
 */
export function calculateHoursAgo(date) {
  const ago = new Date(date);
  const now = new Date();
  const diff = now - ago;
  const minutes = Math.floor(diff / 60000); // minutes
  return Math.floor(minutes / 60); // hours
}

/**
 * Adds a title to the given observation based on its properties.
 *
 * @param {Object} observation - The observation object to which the title will be added.
 * @param {Object} observation.properties - The properties of the observation.
 * @param {number} observation.properties.observation_value - The value indicating if the observation is free or taken.
 * @param {string} observation.properties.date - The date of the observation.
 * @param {string} observation.properties.title - The title to be added to the observation.
 */
export const addTitleToObservation = (observation) => {
  const takenToken = observation.properties.observation_value === 1 ? 'Free' : 'Taken';
  const hours = calculateHoursAgo(observation.properties.date);
  const hoursText = hours === 0 ? '< 1' : hours;
  if (hours > 72) {
    observation.properties.title = `Tomorrow its ${takenToken}`;
    return;
  }
  const popupText = `${takenToken} ${hoursText} hours ago`;
  observation.properties.title = popupText;
};