<template>
  <div id="targeter">
    <p v-if="!timerRunning" class="spacer-text">Tell us - is the parking slot free or taken?</p>
    <p v-if="timerRunning" class="spacer-text">Hint: it takes 2 minutes until you are able to earn your next 10 prken. coins.</p>
    <p v-if="timerRunning" class="spacer-text">Time Elapsed: {{ remainingSeconds }} seconds</p>
    <div v-if="!timerRunning">
      <button class="targeter-button free" @click="fetchGPSLocation(true)" :disabled="isLoading">Free</button>
      <button class="targeter-button taken" @click="fetchGPSLocation(false)" :disabled="isLoading">Taken</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;
</script>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { validateSubmitObservation } from '@/observations/validator';

// Reactive references and store/router setup
const isLoading = ref(false);
const router = useRouter();
const store = useStore();
const user_email = computed(() => store.state.user.email);

// Fetch GPS location
const fetchGPSLocation = (isFree) => {
  if ('geolocation' in navigator) {
    isLoading.value = true;
    navigator.geolocation.getCurrentPosition(
      (position) => {
        sendParkingSpotStatusToServer(isFree, position.coords.latitude, position.coords.longitude);
      },
      (error) => {
        console.error(error);
        isLoading.value = false;
        alert('Error getting location: ' + error.message);
      }
    );
  } else {
    alert('Geolocation is not supported by this browser.');
  }
};

// Send parking spot status to the server
const sendParkingSpotStatusToServer = async (isFree, latitude, longitude) => {
  isLoading.value = true;
  console.log(`Updating status to: ${isFree ? 'Free' : 'Occupied'}`);
  console.log(`GPS Location: ${latitude}, ${longitude}`);
  console.log(`User email: ${user_email.value}`);

  const payload = {
    type: 'Feature',
    geometry: {
      type: "Point",
      coordinates: [longitude, latitude],
    },
    properties: {
      date: new Date(),
      user_email: user_email.value,
      observation_value: isFree ? 1 : 0,
    }
  };

  const validation = await validateSubmitObservation(payload);
  if (!validation.valid) {
    isLoading.value = false;
    return;
  }

  try {
    await axios.post('/api/parking-spots', validation.data);
    router.push('/confirmation');
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

// Lifecycle hook for mounting the Leaflet map
onMounted(() => {
  store.dispatch('fetchLastObservationTime');

  if (store.state.timerRunning) {
    isLoading.value = true;
  }
});

// Computed properties for reactivity with Vuex store
const remainingSeconds = computed(() => store.getters.remainingSeconds);
const timerRunning = computed(() => store.getters.timerRunning);

watch(
  () => store.getters.timerRunning,
  (newValue) => {
    isLoading.value = newValue;
  }
);
</script>

<style scoped>
#targeter {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 2;
  width: 360px;
}

.targeter-img {
  width: 100%;
  height: auto;
  margin: 30px auto;
}

.spacer-text {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.targeter-button {
  color: white;
  border: 0px solid #333333;
  border-radius: 30px;
  padding: 10px;
  margin: 10px;
  width: 100px;
  font-weight: 500;
  box-shadow: 2px 2px 5px wheat;
  font-size: 23px;
}

.free {
  background-color: #98ffc3;
}

.taken {
  background-color: #ff6086;
}

button:disabled{
  background-color: #cccccc;
  color: #666666;
}
</style>