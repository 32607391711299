<template>
  <div id="prken-land">
    <div ref="mapContainerRef" class="map-container">
      <div id="map-coin-visualizer">
        <img class="map-prken-coin" src="@/assets/prken_coin_small.png" alt="Coins" />
        <div class="map-coin-text">{{ coins }}</div>
      </div>
      <div id="map-date-visualizer">
        <div class="map-date-text">{{ formattedDate }}</div>
      </div>
    </div>
    <div id="content-container">
      <div id="content-container-inner">
        <PlayComponent v-if="showPlay" />
        <CarouselComponent v-if="showReplay" @emitSliderChange="onSliderEventCallback"
          :previousSliderIdx="carouselSliderValue" />
        <LayersControlComponent v-if="showLayers" @emitLayerChange="onLayerSelectEventCallback"
          :avatarLayerVisible="avatarLayerVisibleRef" :freeParkingLayerVisible="freeParkingLayerVisibleRef"
          :takenParkingLayerVisible="takenParkingLayerVisibleRef" />
      </div>
    </div>
    <div id="bottom-control-bar-container">
      <div class="bottom-button button-play" @click="onClickBottomControlBarCallback('play')">
        <img src="@/assets/play-icon.png" alt="Play" />
        <p>Play</p>
      </div>
      <div class="bottom-button button-replay" @click="onClickBottomControlBarCallback('replay')">
        <img src="@/assets/replay-icon.png" alt="Replay" />
        <p>Replay</p>
      </div>
      <div class="bottom-button button-layers" @click="onClickBottomControlBarCallback('layers')">
        <img src="@/assets/layers-icon.png" alt="Layers" />
        <p>Layers</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';

import 'maplibre-gl/dist/maplibre-gl.css';

import { createMap, initializePrkenMap, addObservationsToMap, updateObservationsLayerVisibility } from '@/prken_land/mapUtils';
import { onMapClickCallback, onMapMoveEndCallback } from '@/prken_land/mapEventCallbacks';
import { getUserCoins } from '@/user/loader';

import CarouselComponent from '@/prken_land/CarouselComponent.vue';
import PlayComponent from '@/prken_land/PlayComponent.vue';
import LayersControlComponent from '@/prken_land/LayersControlComponent.vue';

// References for the map container and the map instance
const mapContainerRef = ref(null); // HTML element reference
const mapInstanceRef = ref(null); // Map instance reference

const carouselSliderValue = ref(1);

// Show replay flag.
const showPlay = ref(false);
const showReplay = ref(false);
const showLayers = ref(false);

const avatarLayerVisibleRef = ref(true);
const freeParkingLayerVisibleRef = ref(true);
const takenParkingLayerVisibleRef = ref(true);

// Selected date for data view.
const selectedDate = ref(new Date());

const coins = ref(0);

const formattedDate = computed(() => {
  return `${selectedDate.value.getDate()}.${selectedDate.value.getMonth() + 1}.${selectedDate.value.getFullYear()}`;
});

onMounted(async () => {
  coins.value = await getUserCoins();

  mapInstanceRef.value = createMap(mapContainerRef);  // Create MapLibre GL JS map

  mapInstanceRef.value.on('load', async () => {
    await initializePrkenMap(mapInstanceRef.value);
  });

  // Register callbacks on different events.
  mapInstanceRef.value.on('click', onMapClickCallbackWrapper);
  mapInstanceRef.value.on('moveend', onMapMoveEndCallbackWrapper);

  // Open play page by default.
  onClickBottomControlBarCallback('play');
});

onBeforeUnmount(() => {
  // Clean up the map instance to avoid memory leaks
  if (mapInstanceRef.value) {
    mapInstanceRef.value.off('click', onMapClickCallbackWrapper);
    mapInstanceRef.value.off('moveend', onMapMoveEndCallbackWrapper);
    mapInstanceRef.value.remove();
    mapInstanceRef.value = null;
  }
});

/**
 * Callback function to handle layer visibility changes on the map.
 *
 * @param {boolean} avatarLayerVisible - Indicates whether the avatar layer should be visible.
 * @param {boolean} freeParkingLayerVisible - Indicates whether the free parking layer should be visible.
 * @param {boolean} takenParkingLayerVisible - Indicates whether the taken parking layer should be visible.
 */
const onLayerSelectEventCallback = (avatarLayerVisible, freeParkingLayerVisible, takenParkingLayerVisible) => {
  if (!mapInstanceRef.value) {
    return;
  }

  avatarLayerVisibleRef.value = avatarLayerVisible;
  freeParkingLayerVisibleRef.value = freeParkingLayerVisible;
  takenParkingLayerVisibleRef.value = takenParkingLayerVisible;

  updateObservationsLayerVisibility(mapInstanceRef.value, {
    avatars: avatarLayerVisible,
    free: freeParkingLayerVisible,
    taken: takenParkingLayerVisible,
  });
};

/**
 * Callback function to handle clicks on the bottom control bar buttons.
 * Depending on the button clicked, it performs different actions.
 *
 * @param {string} buttonName - The name of the button that was clicked.
 * 
 * - 'play': Calls handlePlayButtonClick with the content container element.
 * - 'replay': Calls handleReplayButtonClick with the content container element.
 * - 'layers': Calls handleLayersButtonClick with the content container element.
 * - default: Logs 'Default.' to the console.
 * 
 * After handling the button click, it adjusts the position of the bottom control elements.
 */
const onClickBottomControlBarCallback = (buttonName) => {
  switch (buttonName) {
    case 'play': {
      handleButtonClick('play', '120px')
      break;
    }
    case 'replay': {
      handleButtonClick('replay', '55px');
      break;
    }
    case 'layers': {
      handleButtonClick('layers', '130px');
      break;
    }
    default:
      console.log('Default.');
      break;
  }

  // Move the bottom control bar items in the map to adjust for the new height.
  adjustPositionMapBottomControlElements();
};

/**
 * Handles the button click event and toggles the visibility of different sections based on the button type.
 * It also adjusts the height of the content container accordingly.
 *
 * @param {string} buttonType - The type of button that was clicked ('play', 'replay', or 'layers').
 * @param {string} height - The height to set for the content container when a section is shown.
 */
const handleButtonClick = (buttonType, height) => {
  console.log(`${buttonType} button clicked`);
  showPlay.value = buttonType === 'play' ? !showPlay.value : false;
  showReplay.value = buttonType === 'replay' ? !showReplay.value : false;
  showLayers.value = buttonType === 'layers' ? !showLayers.value : false;

  const contentContainer = document.getElementById('content-container');
  contentContainer.style.height = showPlay.value || showReplay.value || showLayers.value ? height : '0px';
};

const adjustPositionMapBottomControlElements = () => {
  const bottomDateContainer = document.getElementById('map-date-visualizer');
  const bottomControlBarContainer = document.getElementsByClassName('maplibregl-ctrl-bottom-right ')[0];
  const contentContainerHeight = document.getElementById('content-container').clientHeight;

  bottomControlBarContainer.style.bottom = `${contentContainerHeight}px`;
  bottomDateContainer.style.bottom = `${contentContainerHeight}px`;
};

/**
 * Wrapper function for handling the end of a map move event.
 * Ensures that the map instance is available before calling the actual callback.
 * 
 * @async
 * @function onMapMoveEndCallbackWrapper
 * @returns {Promise<void>}
 */
const onMapMoveEndCallbackWrapper = async () => {
  if (!mapInstanceRef.value) {
    return;
  }

  await onMapMoveEndCallback(mapInstanceRef.value);
};

/**
 * Wrapper function for handling map click events.
 * Ensures that the map instance is available before calling the actual callback.
 * 
 * @function onMapClickCallbackWrapper
 * @param {Object} event - The event object from the map click.
 */
const onMapClickCallbackWrapper = (event) => {
  if (!mapInstanceRef.value) {
    return;
  }

  onMapClickCallback(event, mapInstanceRef.value);
};

/**
 * Callback function for handling slider events.
 * Ensures that the map instance is available before adding observations to the map.
 * Updates the selected date and carousel slider value.
 * 
 * @function onSliderEventCallback
 * @param {Array} observations - The list of observations to add to the map.
 * @param {string} requestedDate - The date requested by the slider.
 * @param {number} sliderValue - The current value of the slider.
 */
const onSliderEventCallback = (observations, requestedDate, sliderValue) => {
  if (!mapInstanceRef.value) {
    return;
  }

  addObservationsToMap(observations, mapInstanceRef.value);

  selectedDate.value = requestedDate;
  carouselSliderValue.value = sliderValue;
};
</script>

<style scoped src="../prken_land/PrkenLand.css"></style>

<style>
.maplibregl-popup-content {
  border-radius: 30px;
  padding: 8px 20px;
}

.maplibregl-popup-close-button {
  display: none;
}
</style>