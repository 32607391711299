import axios from 'axios';
import { validateReceiveObservations } from '@/observations/validator';

// Configure Axios
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

/**
 * Queries observations for a specific day and validates them.
 * If the selected date is in the future, it adjusts the date to a week before.
 * It then queries the backend for observations on the selected date,
 * validates the received observations, and filters out observations
 * that are not within the selected day.
 *
 * @param {Date} selectedDate - The date for which to query observations.
 * @returns {Promise<Array>} A promise that resolves to an array of validated observations.
 */
export async function queryObservationsOnDayN(selectedDate) {
  let validatedObservations = [];
  try {
    console.log(`Querying observations for day ${selectedDate}`);

    const today = new Date();
    if (selectedDate.getTime() > today.getTime()) {
      console.log("Selected date is in the future.");
      selectedDate.setDate(selectedDate.getDate() - 7);
    }

    // Query observations for the selected date from the backend.
    const response = await axios.post('/api/all-observations-on-day-n', {
      date: selectedDate.toISOString(),
    });

    // Validate observations with avatars
    const validation = await validateReceiveObservations(response.data);
    if (!validation.valid) {
      console.log("Validation of received observations failed.");
      return;
    }

    // Account for UTC backend time and fronted locale.
    // Delete observations that are not within the selected day.
    validatedObservations = validation.data.filter(entry => {
      const entryDate = new Date(entry.properties.date);
      return entryDate.toDateString() === selectedDate.toDateString();
    });
  } catch (error) {
    console.error(error);
  }

  return validatedObservations;
}