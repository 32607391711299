import * as yup from 'yup';
import { featureSchema } from '@/geojson/validator';

const propertiesSchema = yup.object({
  date: yup.date().required('Observation date is required'),
  observation_value: yup.number().required('Observation value is required'),
}).noUnknown(true);

// Schema for submitting an observation
const propertiesSubmitSchema = propertiesSchema.shape({
  user_email: yup.string().email('User email must be a valid email').required('User email is required'),
}).noUnknown(true);

const observationSubmitSchema = featureSchema.shape({
  properties: propertiesSubmitSchema,
}).noUnknown(true);

// Function to validate a user entry
export const validateSubmitObservation = async (observation) => {
  try {
    const options = { stripUnknown: false, abortEarly: false };
    const validData = await observationSubmitSchema.validate(observation, options);
    return { valid: true, data: validData };
  } catch (error) {
    console.error('Validation errors:', error.errors);
    return { valid: false, errors: error.errors };
  }
};

// Schema for receiving observations from the backend.
const propertiesReceiveSchema = propertiesSchema.shape({
  avatar: yup.string().required('Avatar is required'),
}).noUnknown(true);

const observationReceiveSchema = featureSchema.shape({
  properties: propertiesReceiveSchema,
}).noUnknown(true);

// Collection of observations schema with avatars
const observationsReceiveSchema = yup.array().of(observationReceiveSchema);

export const validateReceiveObservations = async (observations) => {
  try {
    const options = { stripUnknown: false, abortEarly: false };
    const validData = await observationsReceiveSchema.validate(observations, options);
    return { valid: true, data: validData };
  } catch (error) {
    console.error('Validation errors:', error.errors, error);
    return { valid: false, errors: error };
  }
};