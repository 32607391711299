<template>
  <div id="layers-control-container">
    <p class="spacer-text">Select layers to visualize:</p>
    <div id="layers-container">
      <div class="icon-wrapper">
        <div :class="{
          'layer-icon': true,
          'avatar-layer': true,
          'disabled': avatarLayerVisibleRef === false,
        }" @click="toggleLayer('avatar')"></div>
        <p :class="{
          'icon-wrapper-text': true,
          'disabled-text': avatarLayerVisibleRef === false,
        }">Avatars</p>
      </div>
      <div class="icon-wrapper">
        <div :class="{
          'layer-icon': true,
          'free-parking-layer': true,
          'disabled': freeParkingLayerVisibleRef === false,
        }" @click="toggleLayer('free-parking')"></div>
        <p :class="{
          'icon-wrapper-text': true,
          'disabled-text': freeParkingLayerVisibleRef === false,
        }">Free Slots</p>
      </div>
      <div class="icon-wrapper">
        <div :class="{
          'layer-icon': true,
          'taken-parking-layer': true,
          'disabled': takenParkingLayerVisibleRef === false,
        }" @click="toggleLayer('taken-parking')"></div>
        <p :class="{
          'icon-wrapper-text': true,
          'disabled-text': takenParkingLayerVisibleRef === false,
        }">Taken Slots</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch, onMounted } from 'vue';

const emit = defineEmits(['emitLayerChange']);

// Reactive references for the layer visibility
const avatarLayerVisibleRef = ref(true);
const freeParkingLayerVisibleRef = ref(true);
const takenParkingLayerVisibleRef = ref(true);

const props = defineProps({
  avatarLayerVisible: Boolean,
  freeParkingLayerVisible: Boolean,
  takenParkingLayerVisible: Boolean,
});

watch(() => props.avatarLayerVisible, (value) => {
  avatarLayerVisibleRef.value = value;
});

watch(() => props.freeParkingLayerVisible, (value) => {
  freeParkingLayerVisibleRef.value = value;
});

watch(() => props.takenParkingLayerVisible, (value) => {
  takenParkingLayerVisibleRef.value = value;
});

onMounted(() => {
  avatarLayerVisibleRef.value = props.avatarLayerVisible;
  freeParkingLayerVisibleRef.value = props.freeParkingLayerVisible;
  takenParkingLayerVisibleRef.value = props.takenParkingLayerVisible;
});

// Method to toggle the visibility of a layer
const toggleLayer = (layer) => {
  switch (layer) {
    case 'avatar':
      avatarLayerVisibleRef.value = !avatarLayerVisibleRef.value;
      break;
    case 'free-parking':
      freeParkingLayerVisibleRef.value = !freeParkingLayerVisibleRef.value;
      break;
    case 'taken-parking':
      takenParkingLayerVisibleRef.value = !takenParkingLayerVisibleRef.value;
      break;
  }
  emit('emitLayerChange', avatarLayerVisibleRef.value, freeParkingLayerVisibleRef.value, takenParkingLayerVisibleRef.value);
}
</script>

<style scoped>
#layers-control-container {
  min-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

#layers-container {
  min-width: 320px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.layer-icon {
  cursor: pointer;
  text-align: center;
  width: 60px;
  height: 60px;
  border-radius: 15px;
  border: 2px solid #608aff;
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar-layer {
  background-image: url('@/assets/avatar-layer-icon.png');
  background-size: cover;
  background-position: center;
}

.free-parking-layer {
  background-image: url('@/assets/free-layer-icon.png');
  background-size: cover;
  background-position: center;
}

.disabled {
  border: 0px solid #608aff;
}

.icon-wrapper-text {
  font-size: 12px;
  color: #608aff;
  font-weight: 500;
}

.disabled-text {
  color: #333;
}

.taken-parking-layer {
  background-image: url('@/assets/taken-layer-icon.png');
  background-size: cover;
  background-position: center;
}

.spacer-text {
  margin-top: 10px;
  margin-bottom: 15px;
  margin-left: 10px;
  margin-right: 10px;
}
</style>