import axios from 'axios';

// Configure Axios
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

/**
 * Queries parking slots within the specified bounds.
 *
 * @param {Object} bounds - The geographical bounds to query within.
 * @param {Function} bounds.getSouthWest - Function to get the south-west coordinates of the bounds.
 * @param {Function} bounds.getNorthEast - Function to get the north-east coordinates of the bounds.
 * @returns {Promise<Array>} A promise that resolves to an array of parking slots.
 */
export async function queryParkingSlots(bounds) {
  let parkingSlots = [];
  try {
    const response = await axios.post('/api/query-parking-slots-within-bounds', {
      southWest: bounds.getSouthWest(),
      northEast: bounds.getNorthEast(),
    });
    parkingSlots = response.data;
  } catch (error) {
    console.error(error);
  }

  return parkingSlots;
}
