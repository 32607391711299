<template>
  <div id="carousel-container">
    <div class="arrow-container arrow-container-left">
      <svg @click="slider.prev()" :class="{
        arrow: true,
        'arrow--left': true,
      }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"></path>
      </svg>
    </div>
    <div ref="containerRef" class="keen-slider">
      <div v-for="n in 4" :key="n" :class="{
        'keen-slider__slide': true,
        [`number-slide${n}`]: true,
        'active-slide': n === 3,
      }">
        {{ getDateMark(n) }} <span class="day-text">{{ getDateDay(n) }}</span>
      </div>
    </div>

    <div class="arrow-container arrow-container-right">
      <svg v-if="slider" @click="slider.next()" :class="{
        arrow: true,
        'arrow--right': true,
      }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path>
      </svg>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch } from 'vue'

import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/vue'

import { queryObservationsOnDayN } from "@/observations/api";

const emit = defineEmits(['emitSliderChange']);

const props = defineProps({
  previousSliderIdx: Number,
});

watch(() => props.previousSliderIdx, (value) => {
  console.log(`Previous slider index: ${value}`);
});

const current = ref(1);
const [containerRef, slider] = useKeenSlider({
  loop: true,
  initial: 1, // Start at the 1th slide
  slides: {
    perView: 3,
    spacing: 0,
  },
  slideChanged: (s) => {
    onSlideChanged(s);
  },
  created: (s) => {
    onSlideCreated(s);
  },
});

const onSlideCreated = async (s) => {
  console.log('Slide created');
  s.moveToIdx(props.previousSliderIdx);
};

const onSlideChanged = async (s) => {
  current.value = s.track.details.rel;
  console.log(`Current slide: ${current.value}`);

  // Calculate +1 index and wrap around, as keen-slider uses 1-based index but the left most element is 1.
  // And we want the 2nd element, which is the middle element, to be the active one.
  const index = ((current.value + 1) % 4) + 1;
  console.log(`Current wrapped index: ${index}`);

  // Update the active slide element with .active-slide class
  const selectedElement = document.querySelector(`.number-slide${index}`);
  console.log(`Selected element: ${selectedElement}`);
  if (selectedElement) {
    const active = document.querySelector('.active-slide');
    if (active) {
      active.classList.remove('active-slide');
    }
    selectedElement.classList.add("active-slide");
    console.log(selectedElement.classList.toString());
  }

  // Query observations on the selected date
  console.log(`Selected date: ${sliderDateDict[index].request_date}`);
  const requestDate = new Date(sliderDateDict[index].request_date);
  requestDate.setHours(0, 0, 0, 0);
  console.log(`Request date: ${requestDate}`);
  const observations = await queryObservationsOnDayN(requestDate);

  // Emit the observations and the request date to the parent component
  console.log(`Emitting slider value ${current.value}`);
  emit('emitSliderChange', observations, sliderDateDict[index].display_date, current.value);
};

// Creates short names of a week day (e.g. Mo, Tu, etc.)
const getShortDayName = (date) => {
  const shortWeekDay = date.toLocaleDateString('en-US', { weekday: 'short' });
  return shortWeekDay.slice(0, 2).toUpperCase();
};

const tomorrow = new Date();
tomorrow.setHours(0, 0, 0, 0);
tomorrow.setDate(tomorrow.getDate() + 1);

const sliderDateDict = {
  1: {
    request_date: new Date(new Date() - 2 * 24 * 60 * 60 * 1000), // 2 days ago
    display_date: new Date(new Date() - 2 * 24 * 60 * 60 * 1000), // 2 days ago
  },

  2: { 
    request_date: new Date(new Date() - 24 * 60 * 60 * 1000), 
    display_date: new Date(new Date() - 24 * 60 * 60 * 1000),
  },// 1 day ago
  3: {
    request_date: new Date(),
    display_date: new Date()
  }, // today
  4: {
    request_date: new Date(new Date() - 6 * 24 * 60 * 60 * 1000), // 6 days ago
    display_date: tomorrow // tomorrow
  },
};

const getDateMark = (number) => {
  const date = new Date(sliderDateDict[number].display_date);
  const shortDate = getShortDayName(date);
  return `${shortDate}`;
};

const getDateDay = (number) => {
  const date = new Date(sliderDateDict[number].display_date);
  return date.getDate();
};
</script>

<style scoped>
#carousel-container {
  position: relative;
  z-index: 2;
  min-width: 320px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

[class^="number-slide"],
[class*=" number-slide"] {
  display: flex;
  align-items: first baseline;
  justify-content: center;
  font-size: 25px;
  color: #222222;
  font-weight: 100;
  max-height: 100vh;
}

.day-text {
  font-size: 15px;
  margin-left: 2px;
}

.active-slide {

  background-color: #608aff;
  border-radius: 5px;
  color: white;
  font-weight: 500;
}

.number-slide3 {
  font-weight: 500;
}

.arrow-container {
  height: 50px;
  width: 40px;
}

.arrow-container-right {
  right: 0;
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: rgba(110, 110, 110, 0.5);
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}
</style>