import * as yup from 'yup';

// Define the point schema
export const pointSchema = yup.object({
  type: yup.string().required('Type is required'),
  coordinates: yup.array().of(yup.number()).required('Coordinates are required'),
});

export const featureSchema = yup.object({
  type: yup.string().required('Type is required'),
  geometry: pointSchema.required('Geometry is required'),
  properties: yup.object().noUnknown(true),
});